import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';

const Navbar = ({toggleColorMode, colorMode, userData, handleLogout, handleLogin}) => {
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    // Add an event listener to handle scroll
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleToggle = () => {
        toggleColorMode();
    };

    return (
        <nav
            className={`navbar navbar-expand-lg navbar-light bg-light fixed-top ${
                isScrolled ? 'navbar-scrolled' : ''} ${colorMode}`}>
            <div className="container">
                <Link className="navbar-brand" to="/">
                    Company Logo
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto align-items-center">
                        {userData && (
                            <li className="nav-item">
                                <Link className="nav-link" to="transactions">
                                    Transactions
                                </Link>
                            </li>
                        )}
                        {userData && (
                            <li className="nav-item">
                                <Link className="nav-link" to="upload">
                                    Upload
                                </Link>
                            </li>
                        )}
                        {userData && (
                            <li className="nav-item">
                                <Link className="nav-link" to="settings">
                                    Settings
                                </Link>
                            </li>
                        )}
                        <li className="nav-item">
                            <Link className="nav-link" to="about">
                                About
                            </Link>
                        </li>
                        <li className="nav-item">
                            {userData ? (
                                <div>
                                    Welcome, {userData.displayName}!
                                    <button className="btn btn-primary" onClick={handleLogout}>
                                        Logout
                                    </button>
                                </div>
                            ) : (
                                <button className="google-button" onClick={handleLogin}>
                                    <span className="google-icon"></span>
                                    Sign in with Google
                                </button>
                            )}
                        </li>
                        <li className="nav-item">
                            <div className="form-check form-switch ms-3">
                                <button onClick={handleToggle}>
                                    {colorMode === 'dark' ? 'Light Mode' : 'Dark Mode'}
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
