import React from "react";
import DatabaseConfigsAccountComponent
    from "../components/settings/DatabaseConfigsAccountComponent/DatabaseConfigsAccountComponent";
import DatabaseConfigsCurrencyComponent
    from "../components/settings/DatabaseConfigsCurrencyComponent/DatabaseConfigsCurrencyComponent";

function Settings() {
    return (
        <div>
            <div style={{minHeight: "85vh"}}>
                <div className="container">
                    <div className="row" style={{padding: "10vh 5vh"}}>
                        <h1>Settings</h1>
                        <hr/>
                        <DatabaseConfigsAccountComponent/>
                        <DatabaseConfigsCurrencyComponent/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;