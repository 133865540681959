// NetWorthCard.js
import React from 'react';

const NetWorthCard = () => {
    return (
        <div className="net-worth-card card mt-3">
            <div className="card-body">
                <h6 className="card-title">Net Worth</h6>
                <p className="card-text">Your net worth: $XXXX</p>
            </div>
        </div>
    );
};

export default NetWorthCard;
