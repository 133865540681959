// FileUploader.js
import React from 'react';

const FileUploader = ({onFileChange, onUpload, loading, isFileSelected}) => {
    return (
        <div>
            <div className="mb-3">
                <label htmlFor="fileInput" className="form-label">Select CSV file:</label>
                <input type="file" id="fileInput" accept=".csv" onChange={onFileChange} className="form-control" />
                <button
                    type="button"
                    className="btn btn-primary mt-2"
                    onClick={onUpload}
                    disabled={!isFileSelected}
                >
                    Upload and Process
                </button>
                {loading && <div className="spinner-border text-primary mt-3"></div>}
            </div>
        </div>
    );
};

export default FileUploader;
