import React from 'react';
import PropTypes from 'prop-types';
import './DatabaseConfigsCurrencyComponent.css';

const DatabaseConfigsCurrencyComponent = () => (
  <div className="DatabaseConfigsCurrencyComponent">
    DatabaseConfigsCurrencyComponent Component
  </div>
);

DatabaseConfigsCurrencyComponent.propTypes = {};

DatabaseConfigsCurrencyComponent.defaultProps = {};

export default DatabaseConfigsCurrencyComponent;
