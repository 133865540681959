// AddSingleTransactionModal.js
import './AddSingleTransactionModal.css'
import React, {useState} from 'react';
import TagsInputComponent from "../TagsInputComponent/TagsInputComponent";

const AddSingleTransactionModal = ({
                                       showModal,
                                       handleClose,
                                       switchModal,
                                       accountList,
                                       tagsList,
                                       statusList,
                                       typeList,
                                       currencyList,
                                       userEmail,
                                   }) => {
    const [tags, setTags] = useState([]);
    const [formData, setFormData] = useState({
        type: '',
        description: '',
        tags: tags,
        amount: 0,
        account: '',
        status: '',
        date: getCurrentDate(),
    });

    const handleInputChange = (name, value) => {
        setFormData((prevData) => ({...prevData, [name]: value}));
    };

    const handleAddTransaction = () => {
        // Create the JSON object
        const transactionData = {
            description: formData.description,
            account: formData.account,
            type: formData.type,
            date: formData.date,
            iou: null,
            userEmail: userEmail,
            original_id: null,
            amount: parseFloat(formData.amount),
            currency: formData.currency,
            tags: tags,
            memo: '',
            status: formData.status,
        };

        // Write to console
        console.log(transactionData);

        // Close the modal
        handleClose();
    };

    const handleTagsChange = (newTags) => {
        setTags(newTags);
        console.log(tags);
    };


    return (
        <div className={`modal ${showModal ? 'show' : ''} custom-modal`} tabIndex="-1" role="dialog"
             style={{display: showModal ? 'block' : 'none'}}>
            <div className="modal-dialog custom-modal-dialog" role="document">
                <div className="modal-content custom-modal-dialog">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Single Transaction</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* Form */}
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    {/* Account / Type */}
                                    <div className="form-group">
                                        <label htmlFor="account">Account</label>
                                        <select
                                            className="form-control"
                                            id="account"
                                            name="account"
                                            value={formData.account}
                                            onChange={(e) => handleInputChange('account', e.target.value)}
                                        >
                                            {/* Populate with account list from the system */}
                                            {accountList.map((account) => (
                                                <option key={account.id} value={account.name}>
                                                    {account.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="type">Type</label>
                                        <select
                                            className={`form-control ${
                                                formData.type === 'Expense' || formData.type === 'Transfer' ? 'text-danger' : 'text-success'
                                            }`}
                                            id="type"
                                            name="type"
                                            value={formData.type}
                                            onChange={(e) => handleInputChange('type', e.target.value)}
                                        >
                                            {typeList.map((type) => (
                                                <option key={type.id} value={type.type}>
                                                    {type.type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    {/* Description / Amount */}
                                    <div className="form-group">
                                        <label htmlFor="description">Description</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            value={formData.description}
                                            onChange={(e) => handleInputChange('description', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="amount">Amount</label>
                                        <input
                                            type="number"
                                            className={`form-control ${
                                                formData.type === 'Expense' || formData.type === 'Transfer' ? 'text-danger' : 'text-success'
                                            }`}
                                            id="amount"
                                            name="amount"
                                            value={formData.amount}
                                            onChange={(e) => handleInputChange('amount', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label htmlFor="currency">Currency</label>
                                        <select
                                            className="form-control"
                                            id="currency"
                                            name="currency"
                                            value={formData.currency}
                                            onChange={(e) => handleInputChange('currency', e.target.value)}
                                        >
                                            {/* Populate with currency list from the system */}
                                            {currencyList.map((currency) => (
                                                <option key={currency.id} value={currency.currency}>
                                                    {currency.currency}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    {/* Date / Status */}
                                    <div className="form-group">
                                        <label htmlFor="date">Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="date"
                                            name="date"
                                            value={formData.date}
                                            onChange={(e) => handleInputChange('date', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="status">Status</label>
                                        <select
                                            className="form-control"
                                            id="status"
                                            name="status"
                                            value={formData.status}
                                            onChange={(e) => handleInputChange('status', e.target.value)}
                                        >
                                            {/* Populate with status list from the system */}
                                            {statusList.map((status) => (
                                                <option key={status.id} value={status.status}>
                                                    {status.status}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    {/* Tags */}
                                    <TagsInputComponent newTagsList={tagsList} onTagsChange={handleTagsChange}/>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={switchModal}>
                            Switch to Multiple Transactions
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleAddTransaction}>
                            Add Transaction
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Function to get the current date in 'YYYY-MM-DD' format
const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is a single digit
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
};

export default AddSingleTransactionModal;
