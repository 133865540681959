import React from 'react';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="mt-5 py-3 bg-light text-center">
            <div className="container">
                <p>&copy; {currentYear} {process.env.REACT_APP_OWNER}</p>
            </div>
        </footer>
    );
};

export default Footer;
