import React, { useState } from 'react';

const HeaderBar = ({ title, fromDate, toDate, onDateChange }) => {
    const [datePickerVisible, setDatePickerVisible] = useState(false);

    const toggleDatePicker = () => {
        setDatePickerVisible(!datePickerVisible);
    };

    const handleDateChange = (value, dateType) => {
        onDateChange(value, dateType);
        // Optionally, close the date picker pane after a date is selected
        setDatePickerVisible(false);
    };

    return (
        <div className="header-bar d-flex justify-content-between align-items-center p-3">
            <div className="d-flex align-items-center">
                <i className="fa fa-university" aria-hidden="true" />
                <h2 className="m-0">{title}</h2>
            </div>
            <div className="d-flex align-items-center">
                <button className="btn btn-primary mr-2" onClick={toggleDatePicker}>
                    Select Dates
                </button>
                {datePickerVisible && (
                    <div className="date-picker-pane">
                        <label className="mr-2">From:</label>
                        <input
                            type="date"
                            onChange={(e) => handleDateChange(e.target.value, 'from')}
                            className="form-control mr-2"
                            value={fromDate}
                        />
                        <label className="mr-2">To:</label>
                        <input
                            type="date"
                            onChange={(e) => handleDateChange(e.target.value, 'to')}
                            className="form-control mr-2"
                            value={toDate}
                        />
                    </div>
                )}
                <button className="btn btn-danger mr-2">
                    <i className="fa fa-trash"></i>
                </button>
                <button className="btn btn-info">
                    <i className="fa fa-edit"></i>
                </button>
            </div>
        </div>
    );
};

export default HeaderBar;
