import React, {useEffect, useState} from 'react';
import './LeftSidebarComponent.css';
import TabbedView from "../left/TabbedView/TabbedView";
import NetWorthCard from "../left/NetWorthCard/NetWorthCard";
import UserInformation from "../left/UserInformation/UserInformation";
import LeftListViewComponent from "../left/LeftListViewComponent/LeftListViewComponent";

const LeftSidebarComponent = ({userData, selectedTab, onTabChange, onItemClick}) => {
    return (
        <div className="LeftSidebarComponent">
            <div className="left-sidebar">
                {userData ? (
                    <div>
                        <UserInformation userData={userData}/>
                        <NetWorthCard/>
                        <LeftListViewComponent selectedTab={selectedTab} userData={userData} onItemClick={onItemClick}/>
                        <TabbedView selectedTab={selectedTab} onTabChange={onTabChange}/>
                    </div>
                ) : (
                    <p>Loading user data...</p>
                )}
            </div>
        </div>
    )
};

export default LeftSidebarComponent;
