// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth, GoogleAuthProvider, signInWithPopup,} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCmppAIZXBoO1raLVVEoPfCOt8lBJsOZHQ",
    authDomain: "budget-buddy-78f34.firebaseapp.com",
    projectId: "budget-buddy-78f34",
    storageBucket: "budget-buddy-78f34.appspot.com",
    messagingSenderId: "764988612995",
    appId: "1:764988612995:web:5416290fa22c7eabf096b6",
    measurementId: "G-X6Z7MXMHK0"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore();
export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = () => signInWithPopup(auth, googleProvider);

export const signOutGoogle = () => signOutGoogle(auth, googleProvider);

export const signInWithGoogleInFile = () => {
    signInWithPopup(auth, googleProvider).then((result) => {
        const user = result.user;
        sessionStorage.setItem('userData', JSON.stringify(user));
    }).catch((error) => {
        console.log(error);
    })
}
export default firestore;