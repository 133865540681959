import React from 'react';
import TransactionsComponent from "../components/transactions/TransactionsComponent/TransactionsComponent";

const Transactions = () => {
    return (
        <div>
            <div style={{minHeight: "85vh"}}>
                <div className="container">
                    <TransactionsComponent/>
                </div>
            </div>
        </div>
    );
};

export default Transactions;
