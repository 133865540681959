// UserInformation.js
import React from 'react';

const UserInformation = ({userData}) => {
    return (
        <div className="user-information">
            <img
                src={userData.photoURL || 'path-to-default-image'}
                alt="Profile"
                className="img-fluid rounded-circle"
            />
            <div>
                <h6>{userData.displayName}</h6>
                <p>{userData.email}</p>
            </div>
        </div>
    );
};

export default UserInformation;
