import React, {useState, useEffect} from 'react';
import './TagsInputComponent.css';
import UserInformation from "../left/UserInformation/UserInformation";
import NetWorthCard from "../left/NetWorthCard/NetWorthCard";
import LeftListViewComponent from "../left/LeftListViewComponent/LeftListViewComponent";
import TabbedView from "../left/TabbedView/TabbedView";

const TagsInputComponent = ({newTagsList, onTagsChange}) => {
    const [inputValue, setInputValue] = useState('');
    const [addedTags, setAddedTags] = useState([]);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        onTagsChange(addedTags);
    }, [addedTags, onTagsChange]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        const filteredSuggestions = newTagsList
            .map(tagObj => tagObj.name)
            .filter(tag =>
                tag.toLowerCase().includes(value.toLowerCase())
            );

        setSuggestions(filteredSuggestions);
    };

    const handleTagSelect = (tag) => {
        setInputValue(tag);
        setSuggestions([]);
    };

    const handleAddTag = () => {
        if (inputValue.trim() !== '') {
            const isTagAlreadyAdded = addedTags.some(tag => tag.toLowerCase() === inputValue.trim().toLowerCase());

            if (!isTagAlreadyAdded) {
                setAddedTags((prevTags) => [...prevTags, inputValue.trim()]);
                setInputValue('');
                setSuggestions([]);
            } else {
                console.log('Tag already exists:', inputValue.trim());
                setInputValue('');
                setSuggestions([]);
            }
        }
    };

    const handleRemoveTag = (index) => {
        const updatedTags = [...addedTags];
        updatedTags.splice(index, 1);
        setAddedTags(updatedTags);
    };

    return (
        <div className="mt-4">
            <div className="input-group mb-3">
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Type something..."
                />
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAddTag}
                >
                    Add Tag
                </button>
            </div>

            <div className="card bg-light custom-well-container">
                {inputValue.trim() !== '' && suggestions.length > 0 && (
                    <div>
                        <div className="tags-suggestions-div">
                            <div className="chips-container">
                                {suggestions.map((tag, index) => (
                                    <div
                                        key={index}
                                        className="chip custom-suggestion-chip"
                                        onClick={() => handleTagSelect(tag)}
                                    >
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <hr/>
                    </div>
                )}
                <div>
                    Tags:
                    <div className="chips-container">
                        {addedTags.map((tag, index) => (
                            <div>
                                <div key={index} className="chip custom-suggestion-chip">
                                    {tag}
                                    <button
                                        type="button"
                                        className="close"
                                        aria-label="Close"
                                        onClick={() => handleRemoveTag(index)}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default TagsInputComponent;
