import React, {useEffect} from "react";
import UploadComponent from "../components/file_uploader/UploadComponent/UploadComponent";
import {useNavigate} from "react-router-dom";

function FileUploader() {

    return (
        <div>
            <div style={{minHeight: "85vh"}}>
                <div className="container">
                    <div className="row" style={{padding: "10vh 5vh"}}>
                        <UploadComponent/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileUploader;