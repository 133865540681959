import {addDoc, collection, getDocs, query, where} from 'firebase/firestore';
import db from '../firebase_config';

const createArraysFromParsedData = (parsedDataArray, userEmail) => {
    return parsedDataArray.map((parsedData) => ({
        Status: [{field: 'status', value: parsedData.Status}],
        Currency: [
            {field: 'currency', value: parsedData.Currency},
            {field: 'country', value: null},
        ],
        Type: [
            {field: 'type', value: parsedData.Type},
            {field: 'increment', value: false},
        ],
        Account: [
            {field: 'userEmail', value: userEmail},
            {field: 'name', value: parsedData.Account},
            {field: 'lastUpdated', value: null},
        ],
    }));
};

const uploadToFirestore = async (collectionName, dataArray, userEmail) => {
    try {
        const arrayFromFirestore = await getAllRecords(collection(db, collectionName), collectionName, userEmail);
        const arrayFromFirestoreWOId = arrayFromFirestore.map(({id, ...rest}) => rest);
        let recordsWritten = 0;

        const uniqueDataArrayToFirestore = findUniqueValues(dataArray, arrayFromFirestoreWOId);

        if (uniqueDataArrayToFirestore.length > 0) {
            await Promise.all(
                uniqueDataArrayToFirestore.map(async (item) => {
                    const docRef = await addDoc(collection(db, collectionName), item);
                    console.log(`${collectionName} document added with ID:`, docRef.id);
                    recordsWritten += 1;
                })
            );
        } else {
            console.log(`No unique records to write to ${collectionName}.`);
        }

        console.log(`Total records written to ${collectionName}:`, recordsWritten);
    } catch (error) {
        console.error(`Error uploading data to ${collectionName} Firestore:`, error);
        throw error;
    }
};


const uploadDataToFirestore = async (parsedData, primaryUserEmail) => {
    try {
        const arraysFromParsedData = createArraysFromParsedData(parsedData, primaryUserEmail);
        const uploadPromises = [];

        for (const [collectionName, fieldNames] of [
            ['status', ['Status']],
            ['currency', ['Currency']],
            ['expense_type', ['Type']],
            ['accounts', ['Account']],
            ['tags', ['Tags']], // Add 'tags' collection
        ]) {
            if (collectionName === 'tags') {
                // Process Tags separately
                const processedArray = processTags(parsedData, primaryUserEmail);
                const uniqueTags = removeDuplicatesFromArray(processedArray);

                for (const tag of uniqueTags) {
                    uploadPromises.push(uploadToFirestore(collectionName, [tag], primaryUserEmail));
                }
            } else {
                const processedArray = arraysFromParsedData
                    .flatMap((dataArray) =>
                        Object.entries(dataArray)
                            .filter(([field]) => fieldNames.includes(field))
                            .map(([field, values]) => ({field, values}))
                    );
                const uniqueArray = removeDuplicatesFromArray(processedArray.map((item) => item.values));
                const statusArray = transformArray(uniqueArray);
                uploadPromises.push(uploadToFirestore(collectionName, statusArray, primaryUserEmail));
            }
        }

        await Promise.all(uploadPromises);
        await uploadTransactionsToFirestore(parsedData, primaryUserEmail);
    } catch (error) {
        console.error('Error uploading data to Firestore:', error);
        throw error;
    }
};

const getAllRecords = async (collectionRef, collectionName, userEmail) => {
    try {
        let querySnapshot;

        // Check if the collection requires filtering by userEmail
        if (['accounts', 'tags'].includes(collectionName)) {
            querySnapshot = await getDocs(query(collectionRef, where('userEmail', '==', userEmail)));
        } else {
            querySnapshot = await getDocs(collectionRef);
        }

        return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
    } catch (error) {
        console.error('Error getting records from Firestore:', error);
        throw error;
    }
};

const uploadTransactionsToFirestore = async (parsedData, userEmail) => {
    try {
        const transactionsFromFirestore = await getDataFromTransactionCollection(userEmail);

        const processedTransactionsArray = processingTransactionsFromUpload(parsedData, userEmail);
        const uniqueTransactions = removeDuplicatesFromArray(processedTransactionsArray);

        const existingTransactionIds = transactionsFromFirestore.map((transaction) => transaction.original_id);

        const remainingTransactions = uniqueTransactions.filter((transaction) => {
            return !existingTransactionIds.includes(transaction.original_id);
        });

        await uploadToFirestore('transactions', remainingTransactions, userEmail);
    } catch (error) {
        console.error('Error uploading data to Firestore:', error);
        throw error;
    }
};

async function getDataFromTransactionCollection(userEmail) {
    try {
        const collectionRef = collection(db, 'transactions');
        const querySnapshot = await getDocs(query(collectionRef, where('userEmail', '==', userEmail)));

        return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
    } catch (error) {
        console.error('Error getting records from Firestore:', error.message);
        console.error(error.stack);
        throw error;
    }
}


const processingTransactionsFromUpload = (parsedDataArray, userEmail) => {
    return parsedDataArray.map((transaction) => ({
        original_id: Number(transaction.ID),
        amount: parseFloat(transaction.Amount),
        currency: transaction.Currency,
        date: new Date(transaction.Date).getTime() / 1000, // Convert date to Unix timestamp
        status: transaction.Status,
        iou: transaction.IOU,
        description: transaction.Description,
        memo: transaction.Memo,
        tags: transaction.Tags,
        account: transaction.Account,
        type: transaction.Type,
        userEmail: userEmail,
    }));
};

function processTags(input_array, userEmail) {
    const output_array = [];

    input_array.forEach((item) => {
        if (item.Tags && item.Tags.length > 0) {
            item.Tags.forEach((tag) => {
                output_array.push({
                    name: tag, // Change 'tag' to 'name'
                    userEmail: userEmail,
                });
            });
        }
    });

    // Remove duplicate tags
    return output_array.filter(
        (tag, index, self) =>
            index === self.findIndex((t) => t.name === tag.name && t.userEmail === tag.userEmail)
    );
}

const removeDuplicatesFromArray = (array) =>
    Array.from(new Set(array.map(JSON.stringify))).map(item => JSON.parse(item));

const transformArray = (inputArray) => {
    return inputArray.map((item) => {
        const transformedItem = {};

        item.forEach((property) => {
            transformedItem[property.field] = property.value;
        });

        return transformedItem;
    });
};


function sortObjectKeys(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(sortObjectKeys);
    }

    const sortedObj = {};
    Object.keys(obj).sort().forEach(key => {
        sortedObj[key] = sortObjectKeys(obj[key]);
    });

    return sortedObj;
}

function findUniqueValues(array1, array2) {
    const normalizeAndSort = array => array.map(item => sortObjectKeys(item));

    const normalizedArray1 = normalizeAndSort(array1);
    const normalizedArray2 = normalizeAndSort(array2);

    const stringifyAndSort = array => array.map(item => JSON.stringify(item));

    const sortedArray1 = stringifyAndSort(normalizedArray1);
    const sortedArray2 = stringifyAndSort(normalizedArray2);

    const uniqueValues = sortedArray1.filter(value => !sortedArray2.includes(value));

    // Convert back to original format
    return uniqueValues.map(value => JSON.parse(value));
}

export default uploadDataToFirestore;
