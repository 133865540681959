// TabbedView.js
import React, {useEffect} from 'react';

const TabbedView = ({selectedTab, onTabChange}) => {
    const tabs = ['Accounts', 'Tags', 'Reminders', 'IOUs', 'Favorites'];

    useEffect(() => {
        // Store the last clicked item in localStorage
        if (selectedTab) {
            localStorage.setItem('lastVisitedTab', JSON.stringify(selectedTab));
        }
    }, [selectedTab]);

    return (
        <div className="tabbed-view card mt-3">
            <div className="card-body">
                <ul className="nav nav-pills">
                    {tabs.map((tab) => (
                        <li key={tab} className="nav-item">
                            <a
                                href="#!"
                                className={`nav-link ${selectedTab === tab ? 'active' : ''}`}
                                onClick={() => onTabChange(tab)}
                            >
                                {tab}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TabbedView;
