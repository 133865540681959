// RightMainSectionComponent.js
import React, { useState, useEffect } from 'react';
import HeaderBar from '../right/HeaderBar/HeaderBar';
import TransactionTable from '../right/TransactionTable/TransactionTable';
import { collection, getDocs, query, where } from 'firebase/firestore';
import db from '../../../firebase_config';

const RightMainSectionComponent = ({ userData, selectedTab, selectedItem }) => {
    const [title, setTitle] = useState(`${selectedTab} - ${selectedItem?.name || 'All Accounts'}`);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [records, setRecords] = useState([]);

    useEffect(() => {
        setTitle(`${selectedTab} - ${selectedItem?.name || 'All Accounts'}`);

        const currentDate = new Date();
        setFromDate(currentDate.toISOString().split('T')[0]);
        setToDate(currentDate.toISOString().split('T')[0]);

        const fetchRecords = async () => {
            try {
                let q;

                if (selectedTab === 'Accounts') {
                    if (selectedItem) {
                        if (JSON.stringify(selectedItem).includes('All')) {
                            q = query(collection(db, 'transactions'), where('userEmail', '==', userData.email));
                        } else {
                            q = query(
                                collection(db, 'transactions'),
                                where('userEmail', '==', userData.email),
                                where('account', '==', selectedItem?.name || null)
                            );
                        }
                    } else {
                        const defaultItemQ = query(
                            collection(db, 'transactions'),
                            where('userEmail', '==', userData.email)
                        );
                        const defaultItemSnapshot = await getDocs(defaultItemQ);
                        const defaultItemData = defaultItemSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                        if (defaultItemData.length > 0) {
                            q = query(
                                collection(db, 'transactions'),
                                where('userEmail', '==', userData.email),
                                where('account', '==', defaultItemData[0]?.name || null)
                            );
                        }
                    }
                } else {
                    q = query(
                        collection(db, 'transactions'),
                        where('userEmail', '==', userData.email),
                        where('tags', 'array-contains', selectedItem?.name || '')
                    );
                }

                const querySnapshot = await getDocs(q);
                const transactionData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setRecords(transactionData);
            } catch (error) {
                console.error('Error fetching records:', error.message);
            }
        };

        fetchRecords();
    }, [selectedTab, selectedItem, userData.email]);

    const handleDateChange = (newDate, when) => {
        if (when === 'to') {
            setToDate(newDate);
        } else {
            setFromDate(newDate);
        }
        // Optionally, you can fetch records for the new date here and update the state
    };

    return (
        <div className="right-main-section">
            {userData ? (
                <div>
                    <HeaderBar title={title} fromDate={fromDate} toDate={toDate} onDateChange={handleDateChange} />
                    <TransactionTable records={records} />
                </div>
            ) : (
                <p>Loading user data...</p>
            )}
        </div>
    );
};

export default RightMainSectionComponent;
