import React, { useState, useEffect } from 'react';
import DataTableComponent from '../DataTableComponent/DataTableComponent';
import FileUploader from '../FileUploader/FileUploader';
import Papa from 'papaparse';
import uploadDataToFirestore from '../../../support_functions/FirestoreUploader';

const UploadComponent = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [primaryUserEmail, setPrimaryUserEmail] = useState('');

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];

        setData([]);
        setFile(uploadedFile);
        setIsFileSelected(!!uploadedFile);
    };

    const handleUpload = async () => {
        try {
            setLoading(true);

            await new Promise((resolve) => setTimeout(resolve, 2000));

            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: async (result) => {
                    const dataWithTagsArray = result.data.map((row) => ({
                        ...row,
                        Tags: row.Tags ? row.Tags.split(',').map((tag) => tag.trim()) : [],
                    }));

                    setData(dataWithTagsArray);
                    setLoading(false);

                    await uploadDataToFirestore(dataWithTagsArray, primaryUserEmail);
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                    setLoading(false);
                },
            });
        } catch (error) {
            console.error('Error processing file:', error);
            setLoading(false);
        }
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setCurrentPage(1);
    };

    useEffect(() => {
        const storedData = sessionStorage.getItem('tableData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setData(parsedData);
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('tableData', JSON.stringify(data));
    }, [data]);

    useEffect(() => {
        const userEmail = sessionStorage.getItem('userData')
            ? JSON.parse(sessionStorage.getItem('userData')).email
            : '';
        setPrimaryUserEmail(userEmail);
    }, []);

    return (
        <div className="container mt-5">
            <h4>Welcome, <i>{primaryUserEmail}</i></h4>

            <FileUploader
                onFileChange={handleFileChange}
                onUpload={handleUpload}
                loading={loading}
                isFileSelected={isFileSelected}
            />

            {data.length > 0 && !loading && (
                <div>
                    <div>Total Records: {data.length}</div>
                    <DataTableComponent
                        header={Object.keys(data[0])}
                        body={data}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={setCurrentPage}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            )}
        </div>
    );
};

export default UploadComponent;
