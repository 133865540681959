import React from 'react';

const About = () => {
    return (
        <div>
            <div style={{minHeight: "85vh"}}>
                <div className="container">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>About Us</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                                    tincidunt urna ac justo lacinia, vel ullamcorper elit facilisis.
                                    Suspendisse potenti. In hac habitasse platea dictumst.
                                </p>
                                <p>
                                    Integer consectetur mi ac gravida lobortis. Proin vel ligula
                                    tellus. Sed venenatis sollicitudin ex eu dictum. Integer
                                    tincidunt, nisl a ultrices dapibus, lectus est varius purus, ac
                                    eleifend nisi odio sit amet leo.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <h2>Our Mission</h2>
                                <p>
                                    Nullam eleifend risus a nisl tristique, at sodales ipsum gravida.
                                    Duis nec velit in nisi cursus tristique. Suspendisse potenti.
                                </p>
                                <p>
                                    Morbi rhoncus, elit nec vestibulum fermentum, dui ex fermentum
                                    velit, nec fermentum enim libero sit amet dolor. Fusce dapibus eu
                                    odio nec fermentum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
