// LeftListViewComponent.js
import React, { useState, useEffect } from 'react';
import db from '../../../../firebase_config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import './LeftListViewComponent.css';

const LeftListViewComponent = ({ selectedTab, userData, onItemClick }) => {
    const [contentData, setContentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(
        JSON.parse(localStorage.getItem('lastClickedItem'))
    );

    const defaultItem = {
        id: null,
        lastUpdated: null,
        name: `All Accounts`,
        userEmail: userData.email,
    };

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const collectionName = selectedTab.toLowerCase();
                const q = query(
                    collection(db, collectionName),
                    where('userEmail', '==', userData.email)
                );
                const querySnapshot = await getDocs(q);

                const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                setContentData(data);
                setLoading(false);

                // Set the selected item based on the conditions
                if (selectedTab === 'Accounts') {
                    setSelectedItem(defaultItem);
                } else if (data.length > 0) {
                    setSelectedItem(data[0]);
                } else {
                    setSelectedItem(null);
                }
            } catch (error) {
                console.error(`Error fetching ${selectedTab} content:`, error.message);
            }
        };

        fetchContent();
    }, [selectedTab, userData.email]);

    useEffect(() => {
        // Store the last clicked item in localStorage
        if (selectedItem) {
            localStorage.setItem('lastClickedItem', JSON.stringify(selectedItem));
        }
    }, [selectedItem]);

    useEffect(() => {
        const transformInputArray = (inputArray) => {
            const map = {};
            const result = [];

            inputArray.forEach((item) => {
                map[item.name] = { ...item, children: map[item.name] && map[item.name].children };
                const parent = item.name.split(' / ').slice(0, -1).join(' / ');

                if (parent) {
                    map[parent] = map[parent] || {};
                    map[parent].children = map[parent].children || [];
                    map[parent].children.push(map[item.name]);
                } else {
                    result.push(map[item.name]);
                }
            });

            return result;
        };

        console.log(transformInputArray(contentData));
    }, [contentData]);

    return (
        <div className="tabbed-content card mt-3">
            <div className="card-body">
                <h6 className="card-title">{`${selectedTab} Content`}</h6>
                {loading ? (
                    <p>Loading {selectedTab.toLowerCase()} content...</p>
                ) : (
                    <ul className="list-group">
                        {/* Add "All {selectedTab}" option */}
                        {selectedTab === 'Accounts' && (
                            <li
                                className={`list-group-item ${selectedItem.name === 'All Accounts' ? 'active' : ''} custom-sidebar-list-item`}
                                onClick={() => {
                                    onItemClick(defaultItem);
                                    setSelectedItem(defaultItem);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {`All ${selectedTab}`}
                            </li>
                        )}

                        {contentData.map((item) => (
                            <li
                                key={item.id}
                                className={`list-group-item ${item === selectedItem ? 'active' : ''} custom-sidebar-list-item`}
                                onClick={() => {
                                    onItemClick(item);
                                    setSelectedItem(item);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {item.name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default LeftListViewComponent;
