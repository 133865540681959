// AddMultipleTransactionsModal.js
import React from 'react';

const AddMultipleTransactionsModal = ({showModal, handleClose, switchModal, isAddModalOpen}) => {
    return (
        <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog"
             style={{display: showModal ? 'block' : 'none'}}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add Multiple Transactions</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* Your content for the Add Multiple Transactions modal */}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={switchModal}>
                            Switch to Single Transaction
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleClose}>
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMultipleTransactionsModal;
