import React, {useEffect, useState} from "react";
import {signInWithGoogle} from "../firebase_config";

function Login() {
    const [myObject, setMyObject] = useState(null);

    useEffect(() => {
        // Fetch the JSON object from localStorage
        const storedJsonString = sessionStorage.getItem('userData');

        if (storedJsonString) {
            // Convert the JSON string back to an object
            const storedObject = JSON.parse(storedJsonString);
            setMyObject(storedObject);
        } else {
            // Handle the case when there's no record available in localStorage
            // Set a default value or perform any desired action
            // setMyObject({ name: '', age: 0 });
        }
    }, []);
    return (
        <div>
            <div style={{minHeight: "85vh"}}>
                <div className="container">
                    <div>
                        {myObject ? (
                            <div>
                                <h2>Name: {myObject.displayName}</h2>
                                <h2>Age: {myObject.email}</h2>
                                <img src={myObject.photoURL} alt={myObject.displayName}/>
                            </div>
                        ) : (
                            <div className="row" style={{padding: "10vh 5vh"}}>
                                Login
                                <button onClick={signInWithGoogle}>Sign In with Google</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;