import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {collection, query, where, getDocs, updateDoc,} from 'firebase/firestore';

import logo from './logo.svg';
import './App.css';
import Page404 from "./page/Page404";
import Login from "./page/Login";
import Signup from "./page/Signup";
import Navbar from "./components/Navbar/Navbar";
import db, {signInWithGoogle} from "./firebase_config";
import AlertModal from "./components/AlertModal/AlertModal";
import About from "./page/About";
import Footer from "./components/Footer/Footer";
import FileUploader from "./page/FileUploader";
import Settings from "./page/Settings";
import Transactions from "./page/Transactions";

const Home = () => {
    return (
        <div className="home">
            <h1>this is home</h1>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
};

function App() {
    const [colorMode, setColorMode] = useState(getInitialColorMode());
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('colorMode', colorMode);
        document.documentElement.setAttribute('data-theme', colorMode);
    }, [colorMode]);

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
        }
    }, []);

    function getInitialColorMode() {
        const storedColorMode = localStorage.getItem('colorMode');
        if (storedColorMode) {
            return storedColorMode;
        } else {
            return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        }
    }

    const toggleColorMode = () => {
        setColorMode((prevColorMode) => (prevColorMode === 'light' ? 'dark' : 'light'));
    };

    const handleLogin = async () => {
        try {
            const result = await signInWithGoogle();
            const user = result.user;
            sessionStorage.setItem('userData', JSON.stringify(user));
            setUserData(user);
        } catch (error) {
            console.error(error);
            return;
        }
    };

    useEffect(() => {
        if (userData) {
            checkUserStatus(userData.email);
        }
    }, [userData]);

    const handleLogout = () => {
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('tableData');
        setUserData(null);
        navigate('/');
    };

    const checkUserStatus = async (email) => {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('email', '==', email));

        try {
            const querySnapshot = await getDocs(q);

            if (querySnapshot.size === 1) {
                const userDoc = querySnapshot.docs[0];
                const userData = userDoc.data();

                if (userData.status === 'invited') {
                    await updateDoc(userDoc.ref, {status: 'active'});
                }
            } else {
                throw new Error(
                    'Unauthorized login. Ask the admin to invite you to the system first.'
                );
            }
        } catch (error) {
            console.log(error.message)
            setError(error.message);
            // Set a timeout to clear the error after 10 seconds
            setTimeout(() => {
                setError(null);
                handleLogout();
                navigate('/');
            }, 10000);
        }
    };

    return (
        <div className={`App`}>
            <Navbar toggleColorMode={toggleColorMode} colorMode={colorMode} userData={userData}
                    handleLogout={handleLogout} handleLogin={handleLogin}/>
            <div className="container" style={{marginTop: '80px'}}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="login" element={<Login/>}/>
                    <Route path="signup" element={<Signup/>}/>
                    <Route path="about" element={<About/>}/>
                    <Route path="upload" element={<FileUploader/>}/>
                    <Route path="settings" element={<Settings/>}/>
                    <Route path="transactions" element={<Transactions/>}/>
                    <Route path="404" element={<Page404/>}/>
                    <Route path="/*" element={<Navigate to="404"/>}/>
                </Routes>
            </div>

            {error && (
                <AlertModal
                    message={error}
                    onClose={() => setError(null)}
                />
            )}

            <Footer/>
        </div>
    );
}

export default App;
