// TransactionsComponent.js
import React, {useState, useEffect} from 'react';
import './TransactionsComponent.css';
import LeftSidebarComponent from '../LeftSidebarComponent/LeftSidebarComponent';
import RightMainSectionComponent from '../RightMainSectionComponent/RightMainSectionComponent';
import AddSingleTransactionModal from '../AddSingleTransactionModal/AddSingleTransactionModal';
import AddMultipleTransactionsModal from '../AddMultipleTransactionsModal/AddMultipleTransactionsModal';
import {collection, getDocs, query, where} from "firebase/firestore";
import db from "../../../firebase_config";

const TransactionsComponent = () => {
    const defaultTab = 'Accounts';
    const [selectedTab, setSelectedTab] = useState(defaultTab);
    const [userData, setUserData] = useState({});
    const defaultItem = {
        id: null,
        lastUpdated: null,
        name: `All ${defaultTab}`,
        userEmail: userData.email,
    };
    const [clickedItem, setClickedItem] = useState(defaultItem);
    const [showAddSingleTransactionModal, setShowAddSingleTransactionModal] = useState(false);
    const [showAddMultipleTransactionsModal, setShowAddMultipleTransactionsModal] = useState(false);

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        setUserData(userData);
    }, []);

    useEffect(() => {
        localStorage.setItem('lastVisitedTab', selectedTab);
        localStorage.setItem('lastClickedItem', JSON.stringify(clickedItem));
    }, [selectedTab, clickedItem]);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        console.log(tab);
    };

    const handleItemClick = (item) => {
        setClickedItem(item);
        console.log(item);
    };

    const [accountList, setAccountList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);

    const getAllRecords = async (collectionRef, collectionName, userEmail) => {
        try {
            let querySnapshot;

            // Check if the collection requires filtering by userEmail
            if (['accounts', 'tags'].includes(collectionName)) {
                querySnapshot = await getDocs(query(collectionRef, where('userEmail', '==', userEmail)));
            } else {
                querySnapshot = await getDocs(collectionRef);
            }

            return querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
        } catch (error) {
            console.error('Error getting records from Firestore:', error);
            throw error;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userEmail = sessionStorage.getItem('userData')
                    ? JSON.parse(sessionStorage.getItem('userData')).email
                    : ''; // Replace with the actual user email

                const accounts = await getAllRecords(collection(db, 'accounts'), 'accounts', userEmail);
                const tags = await getAllRecords(collection(db, 'tags'), 'tags', userEmail);
                const status = await getAllRecords(collection(db, 'status'), 'status', userEmail);
                const expenseType = await getAllRecords(collection(db, 'expense_type'), 'expense_type', userEmail);
                const currency = await getAllRecords(collection(db, 'currency'), 'currency', userEmail);

                setAccountList(accounts);
                setTagsList(tags);
                setStatusList(status);
                setTypeList(expenseType);
                setCurrencyList(currency);
            } catch (error) {
                console.error(error)
            }
        };

        fetchData();
    }, []);

    const handleAddTransactionsClick = () => {
        // Determine which modal was last opened
        const lastOpenedModal = localStorage.getItem('lastOpenedModal');

        // Open the appropriate modal based on the last opened modal
        if (lastOpenedModal === 'AddSingleTransactionModal') {
            setShowAddSingleTransactionModal(true);
        } else {
            setShowAddMultipleTransactionsModal(true);
        }
    };

    const handleSwitchModal = () => {
        // Close the current modal and open the other modal
        setShowAddSingleTransactionModal(!showAddSingleTransactionModal);
        setShowAddMultipleTransactionsModal(!showAddMultipleTransactionsModal);

        // Save the last opened modal to local storage
        localStorage.setItem(
            'lastOpenedModal',
            showAddSingleTransactionModal ? 'AddMultipleTransactionsModal' : 'AddSingleTransactionModal'
        );
    };

    return (
        <div className="TransactionsComponent">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-sm-12">
                        <LeftSidebarComponent
                            userData={userData}
                            selectedTab={selectedTab}
                            onTabChange={handleTabChange}
                            onItemClick={handleItemClick}
                        />
                    </div>
                    <div className="col-md-9 col-sm-12">
                        <button type="button" className="btn btn-primary" onClick={handleAddTransactionsClick}>
                            Add Transactions
                        </button>
                        <RightMainSectionComponent
                            selectedTab={selectedTab}
                            userData={userData}
                            selectedItem={clickedItem}
                        />
                    </div>
                </div>
            </div>

            {/* Modals */}
            <AddSingleTransactionModal
                showModal={showAddSingleTransactionModal}
                handleClose={() => setShowAddSingleTransactionModal(false)}
                switchModal={handleSwitchModal}
                accountList={accountList}
                tagsList={tagsList}
                statusList={statusList}
                typeList={typeList}
                currencyList={currencyList}
                userEmail={userData.email}
            />
            <AddMultipleTransactionsModal
                showModal={showAddMultipleTransactionsModal}
                handleClose={() => setShowAddMultipleTransactionsModal(false)}
                switchModal={handleSwitchModal}
                accountList={accountList}
                tagsList={tagsList}
                statusList={statusList}
                typeList={typeList}
                currencyList={currencyList}
            />
        </div>
    );
};

export default TransactionsComponent;
