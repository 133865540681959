// TransactionTable.js
import React, {useEffect, useState} from 'react';
import './TransactionTable.css';

const TransactionTable = ({records}) => {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        // Reset pagination when records change
        setCurrentPage(1);
    }, [records]);

    const totalPages = Math.ceil(records.length / pageSize);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, records.length - 1);
    const currentRecords = records.slice(startIndex, endIndex + 1);

    const handlePageSizeChange = (e) => {
        setPageSize(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
    };

    const convertToDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`
    };

    // TODO: this logic is incorrect. fix it later
    const calculateBalance = (amount, index) => {
        let balance = 0;

        // Iterate through records up to the current index
        for (let i = 0; i <= index; i++) {
            const record = records[i];
            balance += record.amount;
        }

        // Use toFixed to limit the number of fractional points to 2
        return balance.toFixed(2);
    };

    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col">
                    <h4 className="mb-3">Transaction Records</h4>
                    {/* Table */}
                    <table className="table table-bordered table-hover table-sm table-responsive">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Balance</th>
                            <th>Description</th>
                            <th>Tags</th>
                            <th>Accounts</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentRecords.map((record, index) => (
                            <tr key={record.id}>
                                <td>{convertToDate(record.date)}</td>
                                <td className={record.amount < 0 ? 'text-danger' : 'text-success'}>
                                    {record.amount} <span>{record.currency}</span>
                                </td>
                                <td>{calculateBalance(record.amount, index)}</td>
                                <td>{record.description}</td>
                                <td>
                                    {record.tags.map((tag, index) => (
                                        <span key={index} className="badge custom-suggestion-chip">
                                                {tag}
                                            </span>
                                    ))}
                                </td>
                                <td>{record.account}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pagination Controls */}
            <div className="row">
                <div className="col-md-3">
                    <div className="d-flex align-items-center">
                        <label className="me-2">Size:</label>
                        <select className="form-select" onChange={handlePageSizeChange} value={pageSize}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-9 d-flex justify-content-end">
                    <nav aria-label="Page navigation">
                        <ul className="pagination">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={handleFirstPage} tabIndex="-1"
                                        aria-disabled="true">
                                    <i className="fa fa-angle-double-left"></i> {/* Font Awesome icon for double-left arrow */}
                                </button>
                            </li>
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                    <i className="fa fa-angle-left"></i> {/* Font Awesome icon for left arrow */}
                                </button>
                            </li>
                            <li className="page-item">
                                <span className="page-link">{`Page ${currentPage} of ${totalPages}`}</span>
                            </li>
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                    <i className="fa fa-angle-right"></i> {/* Font Awesome icon for right arrow */}
                                </button>
                            </li>
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <button className="page-link" onClick={handleLastPage}>
                                    <i className="fa fa-angle-double-right"></i> {/* Font Awesome icon for double-right arrow */}
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default TransactionTable;
