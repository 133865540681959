import React from "react";

function Signup() {
    return (
        <div>
            <div style={{minHeight: "85vh"}}>
                <div className="container">
                    <div className="row" style={{padding: "10vh 5vh"}}>
                        Signup
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;