import React, { useState, useEffect } from 'react';
import db from '../../../firebase_config';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    where,
    query,
    serverTimestamp
} from 'firebase/firestore';
import './DatabaseConfigsAccountComponent.css';

const DatabaseConfigsAccountComponent = () => {
    const [accounts, setAccounts] = useState([]);
    const [unsavedAccount, setUnsavedAccount] = useState({
        name: '',
    });
    const [isRowModified, setIsRowModified] = useState({});

    const fetchAccounts = async () => {
        try {
            const userEmail = sessionStorage.getItem('userData')
                ? JSON.parse(sessionStorage.getItem('userData')).email
                : '';
            const accountsCollection = await getDocs(
                query(collection(db, 'accounts'), where('userEmail', '==', userEmail))
            );
            const accountsData = accountsCollection.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAccounts(accountsData);
            setIsRowModified(Object.fromEntries(accountsData.map((_, index) => [index, false])));
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    useEffect(() => {
        fetchAccounts();
    }, []);

    const handleAddRow = () => {
        if (!unsavedAccount.name.trim()) {
            setAccounts((prevAccounts) => [...prevAccounts, { name: '' }]);
            setIsRowModified((prevModified) => ({ ...prevModified, [accounts.length]: false }));
        }
    };

    const handleInputChange = (index, fieldName, value) => {
        setAccounts((prevAccounts) => {
            const updatedAccounts = [...prevAccounts];
            updatedAccounts[index][fieldName] = value;
            return updatedAccounts;
        });
        setIsRowModified((prevModified) => ({ ...prevModified, [index]: true }));
    };

    const handleSave = async (index) => {
        try {
            const accountToSave = { ...accounts[index] };
            delete accountToSave.id;
            const userEmail = sessionStorage.getItem('userData')
                ? JSON.parse(sessionStorage.getItem('userData')).email
                : '';
            accountToSave.userEmail = userEmail;

            // Add or update lastUpdated timestamp
            accountToSave.lastUpdated = serverTimestamp();

            if (accounts[index].id) {
                await updateDoc(
                    doc(db, 'accounts', accounts[index].id),
                    accountToSave
                );
            } else {
                const newAccountRef = await addDoc(
                    collection(db, 'accounts'),
                    accountToSave
                );
                accountToSave.id = newAccountRef.id;
            }

            // Update only the modified account, keeping the rest of the accounts unchanged
            setAccounts((prevAccounts) => {
                const updatedAccounts = [...prevAccounts];
                updatedAccounts[index] = accountToSave;
                return updatedAccounts;
            });

            setIsRowModified((prevModified) => ({ ...prevModified, [index]: false }));
            setUnsavedAccount({ name: '' });
        } catch (error) {
            console.error('Error saving account:', error);
        }
    };

    return (
        <div className="container mt-5">
            <table className="table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {accounts.map((account, index) => (
                    <tr key={account.id || index}>
                        <td>{account.id}</td>
                        <td>
                            <input
                                type="text"
                                value={account.name}
                                onChange={(e) =>
                                    handleInputChange(index, 'name', e.target.value)
                                }
                                className="form-control"
                            />
                        </td>
                        <td>
                            <button
                                onClick={() => handleSave(index)}
                                className="btn btn-primary"
                                disabled={!isRowModified[index]}
                            >
                                Save
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <button onClick={handleAddRow} className="btn btn-success">
                Add New Row
            </button>
        </div>
    );
};

export default DatabaseConfigsAccountComponent;
