import React, { useEffect, useState } from 'react';

const AlertModal = ({ message, onClose }) => {
    const [countdown, setCountdown] = useState(10);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (countdown === 0) {
            onClose();
        }
    }, [countdown, onClose]);

    const handleDiscard = () => {
        clearInterval();
        onClose();
    };

    return (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <p>{message}</p>
                        <p>Modal will close in {countdown} seconds</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleDiscard}>
                            Discard
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlertModal;
