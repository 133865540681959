import React from 'react';
import './DataTableComponent.css'

const DataTableComponent = ({ header, body, pageSize, currentPage, onPageChange, onPageSizeChange }) => {
    if (!body || body.length === 0) {
        return <p>No data available</p>;
    }

    const pageCount = Math.ceil(body.length / pageSize);
    const visiblePageNumbers = [];

    // Function to handle page number click
    const handlePageNumberClick = (pageNumber) => {
        onPageChange(pageNumber);
    };

    // Function to handle "First" button click
    const handleFirstButtonClick = () => {
        onPageChange(1);
    };

    // Function to handle "Last" button click
    const handleLastButtonClick = () => {
        onPageChange(pageCount);
    };

    // Generate an array of visible page numbers
    for (let i = 1; i <= pageCount; i++) {
        if (pageCount <= 5 || i === 1 || i === pageCount || (i >= currentPage - 1 && i <= currentPage + 1)) {
            visiblePageNumbers.push(i);
        }
    }

    const lastPage = Math.ceil(body.length / pageSize);

    return (
        <div className="">
            <table className="table mt-3 data-table">
                <thead>
                <tr>
                    {header.map((col, index) => (
                        <th key={index}>{col}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {body
                    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                    .map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {Object.entries(row).map(([key, value], cellIndex) => (
                                <td key={cellIndex}>
                                    {key === 'Tags' && Array.isArray(value)
                                        ? value.join(', ') // Format "Tags" as a comma-separated string
                                        : value}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {body.length > pageSize && ( // Check if pagination is needed
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div>
                        <label htmlFor="pageSizeSelect" className="form-label">
                            Records per page:
                        </label>
                        <select
                            id="pageSizeSelect"
                            onChange={(e) => onPageSizeChange(Number(e.target.value))}
                            value={pageSize}
                            className="form-select"
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                    <div>
                        <div className="pagination">
                            <button
                                className="btn btn-outline-primary"
                                onClick={handleFirstButtonClick}
                                disabled={currentPage === 1}
                            >
                                First
                            </button>
                            {visiblePageNumbers.map((pageNumber, index) => (
                                <button
                                    key={index}
                                    className={`btn btn-outline-primary ${pageNumber === currentPage ? 'active' : ''}`}
                                    onClick={() => handlePageNumberClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            {currentPage + 2 < pageCount && <span className="mx-2">...</span>}
                            {currentPage + 1 < pageCount && (
                                <button className="btn btn-outline-primary" onClick={handleLastButtonClick}>
                                    Last
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {currentPage === lastPage && (
                <div className="mt-3">End of Records</div>
            )}
        </div>
    );
};

export default DataTableComponent;
